.hero-banner.about{
	height: 408px;
	
	.image{
		background-color: rgba( #01161E, 0.6);
		background-blend-mode: multiply;

		@supports (-ms-ime-align: auto) {
			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(#01161e, 0.4);
			}
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			&::after{
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(#01161e, 0.4);
			}
		}
	}

	.overlay{
		.title{
			text-align: center;
			color: $white;
			font-size: 40px;
			font-weight: 400;
			margin-bottom: 20px;
		}

		.subtitle{
			color: $dark-gray;
			font-size: 30px;
			font-weight: 100;
			text-align: center;
		}
	}
}

.history{
	background-color: $white;

	.layout-wrapper{
		margin-top: 100px;
		padding-bottom: 400px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.col{
			.title{
				font-size: 40px;
				color: $midnightblue;
				font-weight: 400;
				margin-bottom: 40px;
			}

			.content{
				font-size: 18px;
				font-weight: 100;
				color: $light-slate-gray;
			}

			&.right{
				margin-left: 80px;

				&:only-child{
					margin-left: 0;
				}
			}

			&.left{
				max-width: 600px;
				width: 100%;
				flex-shrink: 0;
			}


			.video-wrapper{
				max-width: 100%;
				width: 100%;
				position: relative;

				iframe{
					width: 100%;
					height: 440px;					
					z-index: 1;
					position: relative;
				}

				.offset-bg{
					background-color: $light-gray;
					position: absolute;
					width: 100%;
					height: 100%;
					bottom: -20px;
					right: 20px;
					z-index: 0;

					@media(min-width: map-get( $mq-breakpoints, 'phone' )) and ( max-width: map-get( $mq-breakpoints, 'phone-l' ) ){
						bottom: -10px;
						right: 10px;
					}
				}
			}
		}

		@media(max-width: map-get($mq-breakpoints, 'desktop')){
			flex-wrap: wrap;

			.col{
				.title{
					margin-top: 40px;
				}

				&.left{
					max-width: 90%;
					margin: 0 auto;
				}

				&.right{
					margin-left: 0;
				}
			}
		}
		
		@media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'phone-l')){
			margin-top: 40px;

			padding-bottom: 230px;
			
			.col.left{
				max-width: 100%;

				iframe{
					max-height: 200px;
				}

				.offset-bg{
					bottom: -10px;
					right: 10px;
				}
			}

			.col{
				.title{
					margin-bottom: 20px;
				}
			}
		}	

	}
}

.staff{
	background-color: #F3F5F8;
	margin-top: -300px;
	padding-bottom: 120px;

	.layout-wrapper{

		.title{
			font-size: 40px;
			color: $midnightblue;
			font-weight: 400;
			margin-bottom: 30px;
			position: relative;

			span{
				color: $deep-pink;
			}
		}

		.team-members{
			display: grid;
			grid-template-columns: repeat( auto-fill, 380px);
			grid-column-gap: 20px;
			grid-row-gap: 80px;
			justify-content: center;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.team-member{
					max-width: 380px;
					width: 100%;
					margin-bottom: 80px;

					.staff-meta{
						max-width: 100%;
						width: 100%;
					}
				}
			}

			.team-member{
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				.image{
					height: 330px;
					width: 380px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					z-index: 1;
					background-color: $lavender;
				}

				.clipped-block{
					max-width: 380px;
					width: 100%;
					max-height: 330px;
					height: 100%;
					top: 10px;
					right: 10px;
					position: absolute;
					background-color: $light-gray;
					z-index: 0;					
				}

				.staff-meta{
					margin-top: 40px;

					.staff-name,
					.staff-position,
					.staff-bio{
						margin: 0;
					}
					
					.staff-name{
						font-size: 24px;
						font-weight: 400;
						color: $midnightblue;
					}

					.staff-position{
						font-size: 20px;
						margin-bottom: 30px;
						color: #36618C;
						font-weight: 100;
					}

					.staff-bio{
						font-size: 18px;
						color: $light-slate-gray;
						font-weight: 100;
					}
				}
			}

			@media( min-width: map-get( $mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
				grid-template-columns: repeat( auto-fill, 100%);

				.team-member{
					.image{
						width: 100%;
					}
				}
			}

		}
	}
	
	@media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'phone-l')){
		margin-top: -200px;
	}

}

.operation-area.about-us{
	margin-bottom: 100px;
	
	.layout-wrapper{
		align-items: center;
	}

	@media(max-width: 900px ){
		margin-bottom: 0;

		.content{
			padding-bottom: 0;
			padding-top: 40px;
		}

		.icon{
			max-height: 400px;
			margin-top: 0;
		}
		
	}


}