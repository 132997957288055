
.clients-testimonials{
	background-color: $midnightblue;
	
	.testimonial-slider{
		width: 100%;
	}
	
	.slick-list{
		padding: 0 !important;
	}

	.testimonial{
		height: 100%;
		display: flex;
		max-width: 1180px;
	}

	.testimonial-content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 65px 0;
		margin: 0 auto;
		width: 100%;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			padding: 65px 10px;
		}

		.testimonial-image{
			width: 95px;
			height: 95px;
			clip-path: circle(49% at 50% 50%);

			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			background-color: $white;
			margin-bottom: 30px;

			@supports (-ms-ime-align: auto) {
				border-radius: 50%;
			}
		}

		.testimonial-quote{
			font-weight: 400;
			font-size: 30px;
			color: $white;
			text-align: center;
			margin-bottom: 55px;
			max-width: 100%;

			strong{
				color: $deep-pink;
				font-weight: 400;
			}
		}

		.testimonial-author{
			font-size: 26px;
			color: $white;
			font-weight: 100;
		}

		.testimonial-company{
			color: $light-steel-blue;
			font-size: 26px;
			font-weight: 100;
			margin-left: 15px;
		}

		@media( max-width: map-get( $mq-breakpoints, 'tablet' ) ){
			.testimonial-client{
				display: flex;
				flex-direction: column;
				align-items: center;

				.testimonial-company{
					margin-left: 0;
				}
			}
		} 
	}

	.seperator{
		height: 1px;
		width: 100%;
		background-color: #1D4E7D;
	}

	.clients{
		padding-top: 55px;
		padding-bottom: 40px;

		.layout-wrapper{
			max-width: 1260px;
			padding: 0;
		}

		.clients-title{
			color: $white;
			font-size: 26px;
			font-weight: 400;
			margin-bottom: 25px;
			padding: 0 45px;
		}

		.clients-slider-wrap{
			position: relative;
			// padding: 0 45px;

			.clients-slider{				
				margin: 0 45px;
			}
			
			.client{
				width: 130px;
				height: 85px;
				margin-right: 20px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

			.nav-arrow{
				position: absolute;
				top: calc( 50% - 21px);
				padding: 10px;
				
				.icon{
					opacity: .6;
					width: 23px;
					height: 22px;
					transition: opacity .2s linear;
					color:$white;
				}

				&.prev{
					left: 0;
					padding-left: 0;
				}

				&.next{
					right: 0;
					padding-right: 0;
				}

				&:hover{
					cursor: pointer;
					
					.icon{
						opacity: 1;
					}
				}
			}

			@media(max-width: map-get($mq-breakpoints, 'desktop-l')){
				.nav-arrow{
					&.next{
						padding-right: 10px;
					}
					&.prev{
						padding-left: 10px;
					}
				}

				.clients-slider{
					max-width: 90%;
				}
			}

			@media(max-width: 400px){
				.clients-slider{
					max-width: 70%;
					margin: 0 auto;
				}
			}
		}
	}
}