.hero-banner.service{
	height: 520px;

	@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet-l')){
		height: 350px;
	}

	@media(max-width: map-get($mq-breakpoints, 'phone-l')){
		height: 250px;
	}
}

#service-page-intro{
	background-color: $lavender;
	.layout-wrapper{
		padding-bottom: 190px;
		padding-top: 70px;
	}
	.title{
		font-size: 50px;
		font-weight: 400;
		color: $midnightblue;
		text-align: center;
		margin-bottom: 40px;
	}

	.content{
		max-width: 740px;
		width: 100%;
		margin: 0 auto 50px;
		font-size: 20px;
		color: #36618C;
		text-align: center;
	}

	.get-in-touch{
		padding: 20px 0;
		max-width: 240px;
		width: 100%;
		display: block;
		margin: 0 auto;
	}

	@media(max-width: map-get($mq-breakpoints, 'phone-l') ){
		.layout-wrapper{
			padding-bottom: 400px;
		}
	}
}

#service-page-content-cols{
	margin-top: -110px;
	position: relative;
	margin-bottom: 140px;

	.layout-wrapper{
		display: flex;
	}

	.col{
		max-width: 50%;
		width: 100%;
		background-color: $midnightblue;
		display: flex;
		align-items: center;

		&:first-child{
			margin-right: 1px;
		}
		&:last-child{
			margin-left: 1px;
		}

		&:only-child{
			max-width: 100%;
		}

		.inner-wrap{
			padding: 40px 80px;
		}

		.title{
			font-size: 28px;
			color: $white;
			font-weight: 400;
			text-align: left;
			margin-bottom: 30px;
		}

		.content{
			color: $light-gray;
			font-size: 20px;

			ul{
				list-style-type: none;
				padding: 0;
				margin: 0;

				li{
					background-image: url(/images/icons/list-bullet-circle.png);
					background-repeat: no-repeat;
					background-position: left;
					padding-left: 25px;
					margin-bottom: 10px;
				}
			}
		}

		img{
			width: 100%;
		}

		&.environmentAgency{
			.content{
				font-size: 15px;
				color: $white;
				font-weight: 100;
				text-align: center;
				margin-top: 60px;
			}
		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet-l')){
		margin-bottom: 80px;

		.layout-wrapper{
			flex-wrap: wrap;
		}
		.col{
			max-width: 100%;
			margin: 0 0 2px 0;
			
			.inner-wrap{
				width: 100%;
			}

			img{
				margin: 0 auto;
				width: 100%;
				height: auto;
			}

			&:last-child{
				text-align: center;
			}

			.title{
				text-align: center;
			}
		}
	}

	@media(max-width: map-get($mq-breakpoints, 'phone-l') ){
		margin-top: -280px;
		.col{
			.inner-wrap{
				padding: 40px;
			}
		}
	}
}