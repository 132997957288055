// Button structure.
// ---------------------------------------------------------

@mixin button {    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    border: 0 none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    font-family: $font-family;
    font-weight: $font-weight;
    outline: 0 none !important;
    justify-content: center;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    transition-property: background-color, border-color, color, opacity, transform, $focus-property;
    transition-duration: $hover-duration;
    transition-timing-function: $hover-timing-function;
    vertical-align: top;

    &.disabled {
        cursor: default;
        opacity: .4;
        pointer-events: none;
    }
    
    .icon {
        width: 24px;
        height: 24px;
        margin-left: $margin-size--s * (2 / 3);
    }
}

// Button sizing.
// ---------------------------------------------------------

@mixin button--size($height, $font-size: null) {
    @if $font-size == null {
        $font-size: $height * (16px / $widget-size);
    }

    font-size: $font-size;
    height: $height;
    line-height: $height;

    $padding-ew: $height - $font-size;
    padding: 0 round($padding-ew);
}

@mixin button--size-default {
    @include button--size($height: $widget-size);
}

@mixin button--size-s {
    @include button--size($height: $widget-size--s);
}

@mixin button--size-l {
    @include button--size($height: $widget-size--l);
}

// Button colouration.
// ---------------------------------------------------------

@mixin button--skin-color($background-color, $text-color: null, $hover-color: null) {

    @if $text-color == null {
        $text-color: contrasting-color($background-color, $tjs-green, $white);
    }

    @if $hover-color == null {
        $hover-color: mix($background-color, $white);
    }

    background: $background-color;
    color: $text-color;

    &:active,
    &:focus,
    &:hover {
        background: $hover-color;
        color: $text-color;
    }
}

@mixin button--skin-default {
    @include button--skin-color($tjs-green);
}
