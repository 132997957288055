.products-grid{
	display: grid;
	grid-template-columns: repeat(auto-fill, 380px);
	grid-column-gap: 20px;
	grid-row-gap: 30px;
	justify-content: center;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.product{
			flex-shrink: 0;
		}
	}
}

.products, 
.products-grid{
	.product{
		max-width: 380px;

		a.outer{
			text-decoration: none;
			

			&:hover{
				.product-image{
					opacity: .7;
				}
			}
		}

		span{
			display: block;
		}

		.product-meta-wrap{
			max-width: 340px;
			width: 100%;
			background-color: $white;
			margin-top: -75px;
			position: relative;

			.product-meta{
				padding: 35px 0 20px;
				border-bottom: 2px solid $lavender;
				margin: 0 20px;

				.title{
					font-size: 30px;
					font-weight: 400;
					color: $midnightblue;
					margin-bottom: 30px;
					text-align: left;
				}

				.description{
					font-weight: 100;
					color: $light-slate-gray;
					font-size: 18px;
					margin-bottom: 27px;
					text-align: left;
				}

			}
		}

		.suitable-occasions-wrap{
			padding: 20px;
			text-align: left;

			span{
				font-size: 16px;
				color: $light-slate-gray;
				font-weight: 100;
			}

			.suitable-occasions{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 15px;

				a{
					color: $light-slate-gray;
					font-size: 16px;
					padding: 15px;
					border: 2px solid $light-slate-gray;
					text-decoration: none;
					margin-right: 10px;
					margin-bottom: 10px;
					background-image: linear-gradient(120deg, $light-slate-gray 0%, $light-slate-gray 100%);
				
					&:hover{
						color: $white;
					}
				}
			}
		}

		.product-image{
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			width: 100%;
			height: 320px;

			opacity: 1;
			transition: opacity .2s linear;
		}
	}
}


@media( min-width: map-get($mq-breakpoints, 'phone') ) and (max-width: map-get($mq-breakpoints, 'phone-l')){
	.products-grid{
		grid-template-columns: repeat( auto-fill, 100%);

		.product{
			img{
				width: 100%;
			}

			.product-meta-wrap{
				max-width: 90%;
			}

		}
	}
}