// Footer container.
// ---------------------------------------------------------
.layout-footer{
	border-top: 2px solid $deep-pink;

	.layout-wrapper{
		max-width: $content-width;
		padding: 0 20px;
		margin: 70px auto 100px;

		display: grid;
		grid-template-columns: repeat(auto-fill, 280px);
		grid-template-rows: 1fr;
		grid-column-gap: 20px;
		grid-row-gap: 40px;
		justify-content: center;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.footer-col{
				max-width: 280px;
				width: 100%;
			}
		}
		
		.footer-col{

			.footer-col-title{
				font-size: 24px;
				margin: 0;
				color: $midnightblue;
				font-weight: 400;
				padding-bottom: 15px;
				border-bottom: 1px solid $lavender;
				margin-bottom: 30px;
			}

			.footer-col-links{
				display: flex;
				flex-direction: column;

				.footer-text,
				.footer-link{
					color: $light-slate-gray;
					font-size: 18px;
					text-decoration: none;
					font-weight: 400;					
				}

				.footer-link,
				.footer-text{
					margin-top: 0;
					margin-bottom: 15px;

					&:last-child{
						margin-bottom: 0;
					}
				}

				.footer-link{
					&:hover{
						color: #043a6f;
					}
				}
			}

			.footer-social-media{
				margin-top: 30px;

				.title{
					margin: 0;
					font-size: 24px;
					color: $midnightblue;
					font-weight: 400;
				}

				.social-media-links{
					margin-top: 15px;
					font-size: 30px;

					a{
						opacity: 1;
						transition: opacity .2s linear;
						&:hover{
							opacity: .7;
						}
					}

					.facebook{
						color: $facebook;
					}

					.twitter{
						color: $twitter;
					}

					.instagram{
						color: $instagram;
					}
				}
			}

			.accreditations-wrapper{
				display: grid;
				grid-template-columns: repeat(auto-fit, 89px);
				grid-column-gap: 5px;
				grid-row-gap: 5px;
				justify-content: center;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.accreditation{
						margin-bottom: 5px;
					}
				}
				
				.accreditation{
					img{
						border:  1px solid $light-gray;
					}
				}

			}
		}

		@media( min-width: map-get( $mq-breakpoints, 'phone' ) ) and ( max-width: map-get( $mq-breakpoints, 'tablet' ) ){
			grid-template-columns: repeat(auto-fill, 100%);

			.footer-col{
				text-align: center;

				.footer-col-title{
					margin-bottom: 15px;
				}
			}
		}

	}
}

.layout-subfooter{
	background-color: $lavender;

	.layout-wrapper{
		margin: 0 auto;
		max-width: $content-width;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 400;
		padding: 20px;
		color: $light-slate-gray;
		
		a{
			color: $light-slate-gray;
			text-decoration: none;

			&:hover{
				color: $midnightblue;
			}
		}

		.seperator{
			color: $light-steel-blue;
		}
		
		@media( min-width: map-get( $mq-breakpoints, 'phone' ) ) and ( max-width: map-get( $mq-breakpoints, 'tablet' ) ){
			flex-wrap: wrap;

			.copyright{
				margin-bottom: 15px;
			}

			.links{
				display: flex;
				flex-direction: column;
				width: 100%;

				.seperator{
					display: none;
				}

				.subfooter-links{
					padding: 10px 0;
				}
			}

		}
	}
}