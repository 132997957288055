.error-block__wrapper{
	text-align: center;
	margin-top: 60px;
	margin-bottom: 60px;
}

.error-block__status-code{
	color: $deep-pink;
	text-align: center;
	font-size: 80px;
	font-weight: 400;
}

.error-block__status-text{
	color: $midnightblue;
}

.error-block__information{
	color: $light-slate-gray;
}

.error-block__back-button{
	max-width: 300px;
	width: 100%;
	text-align: center;
	margin: 30px auto 0;

	.see-full-range-button{
		justify-content: center;
	}

}