.operation-area{
	
	.layout-wrapper{
		display: flex;
		flex-direction: row;
		
		.content{
			padding-top: 180px;
			padding-bottom: 225px;
			max-width: 580px;
			
			.title{
				font-size: 40px;
				font-weight: 400;
				color: $midnightblue;
				margin: 0 0 40px;

				strong{
					color: $deep-pink;
					font-weight: 400;
				}
			}

			.text{
				font-size: 18px;
				font-weight: 100;
				color: $light-slate-gray;
				margin-bottom: 40px;

				p{
					margin: 0;
				}
			}

			.learn-more{
				text-decoration: none;
				padding: 20px;
				background-color: $midnightblue;
				font-size: 18px;
				color: $white;
				display: inline-block;
			}
		}

		.icon--fa__operation-area{
			margin-top: 100px;
			width: 700px;
			height: 580px;
		}
	}	

	@media( max-width: map-get( $mq-breakpoints, 'tablet-l' ) ){
		.layout-wrapper{
			flex-wrap: wrap;
			text-align: center;
			
			.content{
				padding-bottom: 30px;
				padding-top: 60px;
				max-width: 100%;
			}
			
			.icon--fa__operation-area{
				display: none;
			}

		}
	}
}
