.range-intro{
	background-color: $lavender;

	.layout-wrapper{
		padding: 95px 20px;

		.title{
			margin: 0 0 40px;
			color: $midnightblue;
			font-size: 50px;
			text-align: center;
			font-weight: 400;
		}

		.content{
			font-size: 20px;
			color: $darkslateblue;
			text-align: center;
			max-width: 760px;
			margin: 0 auto;
		}
	}
}

.products-filter{
	margin-bottom: 110px;
	.filters{
		background-color: $midnightblue;
	
		.layout-wrapper{
			display: flex;
			flex-direction: row;
			padding: 25px 20px;
			align-items: center;
		}
		.title{
			font-size: 20px;
			color: $white;
			font-weight: 100;
			margin-right: 40px;
		}

		.filter-wrap{
			display: flex;
			flex-direction: row;		
			flex-grow: 1;
			justify-content: space-between;

			.filter{
				max-width: 180px;
				width: 100%;
				background-color: $filters-bg;
				font-size: 16px;
				font-weight: 100;
				color: $white;
				padding: 20px 0;
				text-align: center;
				transition: all .15s linear;

				&:hover{
					cursor: pointer;
					background-color: $white; 
					color:$midnightblue;
				}

				&.active{
					background-color: $white; 
					color:$midnightblue;
				}
			}

			.mobile-dropdown{
				display: none;
				max-width: 250px;
				width: 100%;
				background-color: #F3F5F8;
				border:none;
				font-family: $urbane;
				font-size: 18px;
				height: 50px;
				padding: 10px 15px;
				color: $midnightblue;
				background-image: url('/images/icons/form-dropdown-arrow.png');
				background-repeat: no-repeat;
				background-position: 90% center;
			}
		}

		@media( max-width: map-get( $mq-breakpoints, 'desktop' ) ){
			.layout-wrapper{
				flex-wrap: wrap;
			}

			.filter-wrap{
				justify-content: flex-end;
				.filter{
					display: none;
				}

				.mobile-dropdown{
					display: block;
				}
			}
		}
		
		@media( max-width: map-get( $mq-breakpoints, 'phone-l' ) ){
			.filter-wrap{
				.mobile-dropdown{
					max-width: 100%;
					margin-top: 20px;
				}
			}
		}

	}

	.products{
		margin-top: 95px;

		.product-wrap{
			width: 100% !important;
		}
	
		.product{
			margin-bottom: 20px;
			width: 100%;
			min-height: 780px;

			
			.product-image{
				width: 100%;
			}

			a:hover{
				
				.image{
					opacity: .7;
				}
			}
		}
	}
}

.our-range.hero-banner{
	height: 520px;

	@media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet')){
		height: 350px;
	}
}