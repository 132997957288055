// Hide visually and from screen readers (H5BP).
// ---------------------------------------------------------

@mixin hidden {
    display: none !important;
    visibility: hidden !important;
}

// Hide only visually, but have it available for screen readers (H5BP):
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
// ---------------------------------------------------------

@mixin visuallyhidden($focusable: false) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    @if $focusable {
        @include visuallyhidden--focusable;
    }
}

// Reset visually hidden

@mixin visuallyhidden-reset {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// Extends visuallyhidden to allow the element to be focusable when navigated to via the keyboard (H5BP):
// https://www.drupal.org/node/897638
// ---------------------------------------------------------

@mixin visuallyhidden--focusable {

    &:active,
    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}
