// Header container.
// ---------------------------------------------------------

.layout-header{
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: $white;
	z-index: 999;
	border-bottom: 2px solid $credibility-bar-bg;
}

.layout-header-nav{
	max-width: 1180px;
	width: 100%;
	margin: 0 auto;

	.layout-wrapper{
		padding: 0;
	}
	
	.layout-header-nav__inner{
		display: grid;
		grid-template-columns: 200px 1fr 200px;
		grid-template-rows: 88px;
		grid-template-areas: "logo nav get-quote";
		align-items: center;
		font-weight: 400;

		@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'phone-l')){
			grid-template-columns: 50% 0 50%;
		}

		//IE11
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		     display: flex;
		     justify-content: space-between;
		     align-items: center;
		     position: relative;
		     height: 88px;
		}
	}

	.icon--logos__fortis-logo-full{
		width: 140px;
		height: 50px;
		grid-area: logo;
	}

	@media( max-width: 1180px) {
		.layout-header-menu__logo{
			padding-left: 20px;
		}
	}

	.layout-header-nav__menu{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		list-style-type: none;
		padding: 30px 0;
		margin: 0;
		grid-area: nav;
		max-width: 680px;
		width: 100%;
		margin: 0 auto;


		.layout-header-menu__item{
			padding-left: 0;

			a{
				color: $darkslateblue;
				font-size: 18px;
				text-decoration: none;

				&:hover,
				&.active{
 					color: #ff007f;
				}
			}
		}
	}

	.layout-header-get-a-quote{
		grid-area: get-quote;

		a{
			background-color: $midnightblue;
			padding: 30px 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			text-decoration: none;
			color: $white;
			font-size: 18px;
			background-image: linear-gradient(120deg, #022944 0%, #022944 100%);				
			justify-content: center;
			height: 88px;
		}

		.icon--fa__get-quote{
			width: 28px;
			height: 28px;
			margin-right: 10px;
		}

	}

}

// mobile menu
.mobile-menu-trigger{
	grid-area: get-quote;
	display: flex;
	justify-content: flex-end;

	.burger-icon{
		width: 58px;
		height: 58px;
		z-index: 1002;
		display: flex;
		flex-direction: column;
		margin-right: 20%;
		padding: 20px;


		.line{
			height: 2px;
			width: 100%;
			background-color: $midnightblue;
			margin: 2px 0;
			transition: all .2s linear;
			transform-origin: left center;

			&.animate{
				background-color: $white;
			}

			&.top{
				top: 0;

				&.animate{
					transform: rotate(42deg);
				}
			}

			&.bottom{
				bottom: 0;
				
				&.animate{
					transform: rotate(-42deg);
				}

			}

			&.middle{
				top: calc( 50% - 1px );
				opacity: 1;

				&.animate{
					opacity: 0;
				}
			}
		}
	}

	//IE11
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		position: fixed;
		top: 0;
		right: 0;
		margin-right: 40px;
		margin-top: 10px;
		//for some reason IE11 includes margins in an element's defined width 
		.burger-icon{
			// width: 78px;
			// height: 58px;
			margin-right: 0;
		}
	}
}

.floating-nav{
	display: none;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: $white;
	z-index: 999;
	border-bottom: 2px solid $credibility-bar-bg;
}

.mobile-menu{
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: $midnightblue;
	z-index: 1001;
	top: 0;
	left: 0;

	.links{
		max-width: 80%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 20% auto;

		//IE11
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			margin: 10% auto;
		}

		.link{
			margin-bottom: 40px;
			font-size: 25px;
			color: $white;
			text-decoration: none;

			// account for really small phone i.e iPhone 5
			@media (min-width: map-get($mq-breakpoints, 'phone')) and (max-width: 400px){
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}
}

@media(max-width: map-get($mq-breakpoints, "desktop") ){
	.layout-header{
		display: none;
	}

	.floating-nav{
		display: block;
	}
}