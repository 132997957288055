.modal {
	position: fixed;
	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	perspective: 1000px;
	z-index: 9999;

	&__overlay {
		width: 100%;
		height: 100%;

		background: rgba($midnight-gray, 0.85);
		opacity: 1;
	}

	&__loading {
		position: absolute;
		top: 50%;
		left: 50%;

		margin: -6px;

		&,
		&::before,
		&::after {
			width: 12px;
			height: 12px;

			background: $white;
			border-radius: 50%;
		}

		&::before {
			position: absolute;
			top: 0;
			left: -18px;
			content: '';
		}

		&::after {
			position: absolute;
			top: 0;
			right: -18px;
			content: '';
		}
	}

	&__dialog {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		align-items: center;
		display: flex;
		justify-content: center;
		pointer-events: none;
		z-index: 5;
	}

	&__body {
		align-items: stretch;
		background: $white;
		display: flex;
		justify-content: center;
		max-height: $modal-max-height;
		max-width: $modal-max-width;
		pointer-events: auto;
		position: relative;
	}

	&__document {
		overflow: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	&__close {
		$button-size: $widget-size--s;
		$button-offset: $margin-size;
		$icon-size: 24px;
		$icon-margin: ($button-size - $icon-size) * 0.5;

		position: absolute;
		top: $button-offset;
		right: $button-offset;

		width: $button-size;
		height: $button-size;

		background: transparent;
		border: 0 none;
		color: $black;
		cursor: pointer;
		outline: 0 none !important;
		padding: 0;
		transition: color $hover-duration $hover-timing-function;
		z-index: 900;

		&:focus,
		&:hover {
			color: $tjs-orange;
		}

		.icon {
			width: $icon-size;
			height: $icon-size;

			margin: $icon-margin;
		}
	}


	// Transitions

	&.is-hidden,
	&.is-hidden &__overlay,
	&__loading.is-hidden,
	&__dialog.is-hidden {
		display: none;
	}

	&__overlay,
	&__dialog {
		transition-property: opacity, transform;
		transition-duration: 0.3s;
	}

	&.is-entering &__overlay,
	&.is-leaving &__overlay {
		opacity: 0;
	}

	&__dialog.is-entering {
		opacity: 0;
		transform: translate3d(0, 0, 60px);
	}

	&__dialog.is-leaving {
		opacity: 0;
		transform: translate3d(0, 0, -60px);
		z-index: 4;
	}
}


html.modal-active,
html.modal-active body {
	overflow: hidden;
}
