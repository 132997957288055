.credibility-bar{
	background-color: $credibility-bar-bg;

	.layout-wrapper{
		padding: 15px 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.item{
			display: flex;
			flex-direction: row;
			align-items: center;

			@media( max-width: map-get($mq-breakpoints, "desktop") ){
				justify-content: center;
			}

			.item-text{
				margin: 0 0 0 10px;
				font-size: 16px;
				color: $white;
				font-weight: 100;

				span{
					color: $deep-pink;
				}	
			}

			.icon--fa__family-run-business{
				font-size: 25px;
			}

			.icon--fa__modern-fleet,
			.icon--fa__technicians
			{
				font-size: 22px;
			}

			.icon--fa__environment{
				font-size: 24px;
			}

		}

	}
}