.blog-breadcrumb{
	margin-top: 40px;
	margin-bottom: 80px;

	a{
		color: $midnightblue;
		font-size: 16px;
		font-weight: 400;
		text-decoration: none;
		display: flex;
		align-items: center;

		.icon{
			color: $light-steel-blue;
			height: 20px;
			width: 20px;
			margin-right: 10px;
			transition: all .2s linear;
		}

		&:hover{
			color: $deep-pink;

			.icon{
				color: $deep-pink;
			}
		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
		margin-bottom: 40px;
	}
}

.article{
	.layout-wrapper{
		border-bottom: 2px solid $lavender;
		display: flex;
		flex-direction: column;
	}
	.title{
		color: $midnightblue;
		font-size: 40px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 20px;
	}

	.date{
		font-size: 22px;
		color: $dark-gray;
		font-weight: 400;
		text-align: center;
		margin-bottom: 45px;
	}

	.image{
		width: 100%;
		height: 560px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		margin-bottom: 90px;

		@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
			height: 350px;
			margin-bottom: 40px;
		}
	}

	.introduction{
		font-size: 26px;
		color: #36618C;
		max-width: 900px;
		width: 100%;
		margin: 0 auto 40px;
		font-weight: 400;
		line-height: normal;
	}

	.content{
		font-size: 18px;
		color: #36618C;
		max-width: 900px;
		width: 100%;
		margin: 0 auto 40px;
		font-weight: 100;
		line-height: normal;

		strong{
			font-weight: 700;
		}

		a{
			color: $deep-pink;
			text-decoration: none;
		}

		img{
			max-width: 900px;
			width: 100%;
			margin-bottom: 30px;
		}

		p{
			margin-bottom: 30px;
		}
	}

	.article-categories{
		max-width: 900px;
		width: 100%;
		margin: 0 auto 90px;

		h5{
			font-size: 18px;
			color: $midnightblue;
			font-weight: 400;
			margin-bottom: 15px;
		}

		.categories{
			display: flex;
			flex-wrap: wrap;

			.category{
				color: #36618C;
				font-size: 18px;
				text-decoration: none;
				padding: 20px 30px;
				background-color: #F3F5F8;
				margin-right: 20px;
				margin-bottom: 10px;
			}
		}
	}
}

.blog-latest.article-page{

	.title{
		margin-top: 80px;
		font-size: 32px;
		color: $midnightblue;
		text-align: center;
		font-weight: 400;
		margin-bottom: 60px;

		span{
			color: $deep-pink;
		}

		.blog-posts{
			margin-top: 0;
		}
	}
}