.product.hero-banner{
	height: 520px;
	
	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
		height: 300px;
	}
}

.features-bar{
	max-width: 900px;
	width: 100%;
	padding: 25px 30px;
	background-color: $white;
	margin: -30px auto 0;
	position: relative;

	ul{
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;

		li{
			background-image: url('/images/icons/list-bullet-dot.png');
			background-repeat: no-repeat;
			background-position: left;
			padding-left: 30px;
			font-size: 18px;
			color: $midnightblue;
			font-weight: 100;
			margin-right: 15px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
		}
	}
	
}

.product-intro{
	background-color: $lavender;

	.layout-wrapper{
		padding-top: 60px;
		text-align: center;

		.title{
			font-size: 50px;
			color: $midnightblue;
			font-weight: 400;
			margin-bottom: 40px;
		}

		.content{
			margin-bottom: 40px;

			p:first-child{
				font-size: 20px;
				font-weight: 400;
			}

			p{
				font-size: 18px;
				color: #36618C;
			}
		}

		.get-in-touch{
			display: inline-block;
		}
	}
}

#product-intro-decoration{
	height: 200px;
	width: 100%;
	background-color: $lavender;
}

#extras{
	margin-top: -120px;
	position: relative;

	.layout-wrapper{
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;

		&.single-col{
			.included,
			.additional{
				max-width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;

				.title{
					margin-bottom: 0;
					margin-right: 50px;
				}

			}
		}
	}
	
	.included,
	.additional{
		max-width: 49.8%;

		@media(max-width: map-get($mq-breakpoints, 'tablet-l')){
			max-width: 100%;

			&:nth-child(2){
				margin-top: 10px;
			}
		}
		width: 100%;
		background-color: $midnightblue;
		padding: 55px 40px 50px;

		.title{
			font-size: 28px;
			color: $white;
			font-weight: 400;
			margin-bottom: 30px;
		}

		.extras{
			color: $light-gray;
			font-size: 20px;

			ul{
				list-style-type: none;
				padding: 0;

				li{
					background-image: url(/images/icons/list-bullet-circle.png);
					background-repeat: no-repeat;
					background-position: left;
					padding-left: 25px;
					margin-bottom: 20px;
				}
			}
		}
	}
}

.see-inside{
	margin-top: 120px;
	margin-bottom: 175px;

	&.no-extras{
		margin-top: 0;
	}

	.title{
		font-size: 40px;
		font-weight: 400;
		color: $midnightblue;
		text-align: center;

		span{
			color: $deep-pink;
		}
	}

	.video-wrap{
		max-width: 910px;
		width: 100%;
		margin: 50px auto 0;
		position: relative;

		iframe{
			width: 100%;
			height: 510px;
		}

		.offset-bg{
			position: absolute;
			bottom: -20px;
			left: -20px;
			height: 100%;
			max-width: 910px;
			width: 100%;
			background-color: #CDD7E2;
			z-index: -1;

			@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'tablet')){
				bottom: -10px;
				left: -10px;
			}

		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone-l')) and ( max-width: map-get($mq-breakpoints, 'tablet-l')){
		margin-bottom: 50px;
		margin-top: 50px;

		.video-wrap{
			iframe{
				height: 300px;
			}
		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
		margin-bottom: 50px;
		margin-top: 50px;

		.video-wrap{
			iframe{
				height: 200px;
			}
		}
	}

}

.specification-floorplan{
	margin-bottom: 135px;

	&.no-video{
		margin-top: 100px;
		
		@media( min-width: map-get($mq-breakpoints, 'phone') ) and (max-width: map-get($mq-breakpoints, 'tablet-l')){
			margin-top: 0;
			margin-bottom: 40px;
		}
	}

	.layout-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.specification{
		max-width: 55%;
		width: 100%;
		margin-right: 5%;
		.title{
			font-size: 40px;
			font-weight: 400;
			color: $midnightblue;
			margin-bottom: 55px;

			span{
				color: $deep-pink;
			}
		}

		.content{
			font-size: 22px;
			color: $midnightblue;
			font-weight: 100;

			strong{
				font-weight: 900;
			}
		}

		&:only-child{
			max-width: 100%;
			margin-right: 0;
			display: flex;
			justify-content: space-between;

			.content{
				max-width: 60%;
			}

			.title{
				max-width: 50%;
				margin-bottom: 0;
			}

			@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet-l')){
				flex-direction: column;
				.title, 
				.content{
					max-width: 100%;
				}

				.title{
					margin-bottom: 40px;
				}
			}
		}

		@media( min-width: map-get($mq-breakpoints, 'phone') ) and (max-width: map-get($mq-breakpoints, 'tablet-l')){
			
		}
	}

	.floorplan{
		position: relative;
		max-width: 40%;
		width: 100%;
		
		.image-wrap,
		.image-bg {
			width: 100%;
			height: 480px;
			background-color: $lavender;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}

		.image-bg{
			background-color: $light-gray;
			position: absolute;
			z-index: -1;
			top: 20px;
			left: -20px;

			@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'tablet')){
				top: 10px;
				left: -10px;
			}

		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'tablet-l')){
		.layout-wrapper{
			justify-content: center;
			flex-direction: column;

			.title{
				text-align: center;
			}
		}

		.specification{
			margin-bottom: 40px;
		}

		.specification{
			max-width: 100%;
			margin-right: 0;
			text-align: center;
		}

		.floorplan{
			max-width: 80%;
			margin: 0 auto;
		}
	}
}

.gallery{
	.layout-wrapper{
		max-width: 1400px;
		padding: 0;
	}

	.images{
		display: grid;
		grid-template-columns: repeat(auto-fill, 25%);
		justify-content: center;

		.image{
			width: 100%;
			height: 300px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			background-color: rgba($credibility-bar-bg, 0);
			background-blend-mode: multiply;

			transition: all .2s linear;

			@media( max-width: map-get($mq-breakpoints, 'tablet-l')){
				height: 200px;
			}
			@media( max-width: map-get($mq-breakpoints, 'tablet')){
				height: 300px;
			}
		
			.overlay{
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				transition: all .2s linear;

				@supports (-ms-ime-align: auto) {
					background-color: rgba($credibility-bar-bg, .6);
				}

				
				.icon{
					color: $white;
					width: 52px;
					height: 52px;
				}
			}

			&:hover{
				cursor: pointer;
				background-color: rgba($credibility-bar-bg, 0.6);
				
				.overlay{
					opacity: 1;
				}
			}
		}

		@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
			grid-template-columns: repeat(auto-fill, 100%);
		}

		//IE11
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: flex;
			flex-wrap: wrap;

			.image{
				width: 25%;
			}
		}
		
	}
}

#lightboxModal{
	display: none;

	width: 100%;
	height: 100%;
	background-color: rgba(0,9,12,.95);
	position: fixed;
	top: 0;
	z-index: 999;

	.modalContent{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: relative;
		flex-direction: column;
		padding: 0 20px;

		@media(max-height: 776px){
			justify-content: flex-start;

			.image{
				max-height: 550px;
			}
		}

		@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet')){
			justify-content: center;

			.image{
				max-height: 250px;
			}
		}

		.gallerySliderWrap{
			max-width: 980px;
			width: 100%;
			max-height: 680px;
		}

		.gallerySlider{
			max-width: 980px;
			
			.sliderItem{
				.image-caption{
					color: $white;
					font-size: 18px;
					font-weight: 100;
					padding-top: 20px;
					text-align: left;
				}
				.image{
					width: 100%;
					height: 600px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
		
					@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet')){
						background-size: contain;
					}
				}
			}
		}

		.modalSlidernav{
			position: absolute;
			top: 50%;
			opacity: .6;
			transition: all .2s linear;

			&:hover{
				cursor: pointer;
				opacity: 1;
			}

			.icon{
				color: $white;
				width: 40px;
				height: 40px;
			}

			&.next{
				right: 30px;
			}

			&.prev{
				left: 30px;
			}

			&.slick-disabled{
				opacity: .3;
			}
		}

		.modalSliderExit{
			color: $white;
			text-align: right;
			padding-bottom: 20px;
			opacity: .7;
			transition: all .2s linear;

			.icon{
				width: 45px;
				height: 45px;
				padding: 10px;
			}

			&:hover{
				opacity: 1;
				cursor: pointer;
			}

			@media (min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet-l')){
				// margin-right: 5%;
			}
		}
	}
}

.product-occasions{
	margin-top: 125px;
	margin-bottom: 90px;

	.title{
		font-size: 40px;
		color: $midnightblue;
		font-weight: 400;
		text-align: center;
		margin-bottom: 45px;
	}

	.suitable-occasions{
		display: grid;
		grid-template-columns: repeat(auto-fill, 380px);
		grid-column-gap: 20px;
		grid-row-gap: 30px;
		justify-content: center;

		.occasion{
			max-width: 380px;
			width: 100%;
			opacity: 1;
			transition: opacity .2s linear;

			&:hover{
				opacity: .7;
			}

			span{
				display: block;
			}
			
			text-decoration: none;

			.occasion-image{
				height: 320px;
				background-size: cover;
				background-position: center;
			}

			.occasion-meta-wrap{
				max-width: 340px;
				width: 100%;
				background-color: $white;
				margin-top: -75px;
				position: relative;
				
				.occasion-meta{
					padding: 35px 0 20px;
					margin: 0 20px;
					border-bottom: 2px solid $lavender;

					.title{
						font-size: 30px;
						font-weight: 400;
						color: $midnightblue;
						margin-bottom: 30px;
						text-align: left;
					}

					.description{
						font-weight: 100;
						color: $light-slate-gray;
						font-size: 18px;
						margin-bottom: 27px;
					}

				}
			}
		}

		//IE11
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

	}

	@media(min-width: 400px) and (max-width: map-get($mq-breakpoints, 'phone-l')){
		margin-top: 50px;
		
		.suitable-occasions{
			grid-template-columns: repeat(auto-fill, 100%);

			.occasion{
				.occasion-meta-wrap{
					max-width: 90%;
				}
			}
		}

	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: 400px){
		margin-top: 50px;
		
		.suitable-occasions{
			grid-template-columns: repeat(auto-fill, 100%);
	
			.occasion{
				.occasion-meta-wrap{
					max-width: 100%;
				}
			}
		}
	}
}