#faq-intro{
	margin-top: 90px;
	margin-bottom: 60px;

	.title{
		font-size: 50px;
		color: $midnightblue;
		font-weight: 400;
		text-align: center;
		margin-bottom: 40px;
	}

	.content{
		text-align: center;
		font-size: 20px;
		color: #36618C;
		font-weight: 400;
		max-width: 750px;
		width: 100%;
		margin: 0 auto;
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
		margin-top: 40px;
	}

	@media( min-width: 400px) and ( max-width: map-get($mq-breakpoints, 'tablet-l')){
		.title{
			font-size: 42px;
		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: 400px){
		.title{
			font-size: 32px;
		}

		margin-bottom: 20px;
	}
}

#faqs{
	margin-bottom: 95px;

	.faq{
		padding: 30px 0;
		border-bottom: 1px solid $light-gray;

		&:hover{
			cursor: pointer;
		}
	
		.question{
			display: flex;
			flex-direction: row;

			h3{
				font-size: 22px;
				color: $midnightblue;
				font-weight: 400;
			}

			.toggle{
				width: 22px;
				height: 22px;
				position: relative;
				margin-right: 20px;
				flex-shrink: 0;
				
				.line{
					position: absolute;
					height: 3px;
					width: 22px;
					background-color: $deep-pink;
					top: calc(50% - 1.5px);
					transition: transform .1s linear;
				
					&.ver{
						transform: rotate(90deg);
						transform-origin: center;
					}
					
				}
			}
		}

		&.active{
			.question{
				.toggle{
					.ver{
						transform: rotate(180deg);
					}
				}
			}
		}

		.answer{
			padding: 40px 40px 30px;
			font-size: 18px;
			color: #36618C;
			font-weight: 100;

			display: none;
		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: 400px){
		.faq .answer{
			padding-right: 10px;
			padding-top: 20px;
			padding-bottom: 0;
		}
	}
}