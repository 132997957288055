.why-choose-us{
	margin-top: 90px;
	margin-bottom: 100px;
	
	@media(max-width: map-get( $mq-breakpoints, 'tablet-l' ) ){
		margin-top: 40px;
	}

	.layout-wrapper{
		text-align: center;
	
		.title{
			font-size: 35px;
			color: $midnightblue;
			font-weight: 400;

			span{
				color: $deep-pink;
			}
		}

		.benefits{
			margin-top: 60px;
			display: grid;
			grid-template-columns: repeat( auto-fill, 270px );
			grid-column-gap: 30px;
			grid-row-gap: 30px;
			justify-content: center;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.benefit{
					max-width: 270px;
					width: 100%;
				}
			}

		
			.benefit{
				display: flex;
				flex-direction: column;

				.image-wrapper{
					height: 85px;
					margin-bottom: 25px;
				}

				.benefit-title{
					min-height: 55px;
					font-size: 22px;
					color: $midnightblue;
					font-weight: 400;
					margin-bottom: 25px;

					span{
						color: $deep-pink;
					}
				}

				.benefit-description{
					color: $light-slate-gray;
					font-size: 18px;
					font-weight: 100;
				}
			}
		}
	}

	&#why-choose-us{
		&:target:before{
			content: '';
			display: block;
			height: 88px;
			margin-top: -88px;
		}
	}
}