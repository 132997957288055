:root {
    .hidden {
        @include hidden;
    }

    .visuallyhidden {
        @include visuallyhidden;
    }

    .visuallyhidden--focusable {
        @include visuallyhidden--focusable;
    }
}
