#blog-intro{
	padding-top: 90px;
	padding-bottom: 80px;
	.title{
		margin-bottom: 40px;
		font-size: 50px;
		color: $midnightblue;
		font-weight: 400;
		text-align: center;

		span{
			color: $deep-pink;
		}
	}

	.content{
		font-size: 20px;
		color: #36618C;
		font-weight: 400;
		max-width: 740px;
		width: 100%;
		text-align: center;
		margin: 0 auto;
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
		padding-top: 40px;
	}
}

.blog-page.blog-latest{
	margin-top: 0;
	margin-bottom: 70px;

	.blog-posts{
		margin: 0;
	}

	.pagination{
		margin-top: 80px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.page{
			color: $midnightblue;
			font-size: 22px;
			font-weight: 400;
			text-decoration: none;
			border: 1px solid $light-gray;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $white;
			transition: all .2s linear;
			margin-left: 5px;
			margin-right: 5px;

			&:hover, 
			&.selected{
				background-color: $midnightblue;
				color: $white;
				border-color: transparent;
			}		
		}

		.pagination-nav{
			margin-left: 20px;
			margin-right: 20px;

			.icon{
				color: #829DB7;
				width: 20px;
				height: 20px;
				opacity: .3;
				transition: opacity .2s linear;

				&:hover{
					opacity: 1;
				}
			}
		}
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
		.post{
			margin-bottom: 40px;
		}
	}
}