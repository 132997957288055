.button {
    @include button;
    @include button--size-default;
    @include button--skin-default;
}

.button--size-s {
    @include button--size-s;
}

.button--size-l {
    @include button--size-l;
}

.get-in-touch{
	background-color: $deep-pink;
	padding: 20px 25px;
	text-decoration: none;
	
	font-weight: 400;
	color: $white;
	z-index: 1;
	text-align: center;
}

.see-full-range-button,
.get-a-quote-button,
.get-in-touch{
	transition: background-color .2s linear;

	&:hover{
		color: $white;
	}
}

.see-full-range-button,
.operation-area .layout-wrapper .content .learn-more{
	&:hover{
		background-color: #032e51;
	}
}

.get-a-quote-button,
.get-in-touch{
	&:hover{
		background-color: #e00086;
	}
}