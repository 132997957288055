// Colour palette.
// ---------------------------------------------------------

$white: #fff;

$midnight-gray: #333;

$black: #000;

$tjs-green: #41a728;

$tjs-orange: #ffad2d;

$darkslateblue: #36618c;

$midnightblue: #043A6F;
$lavender: #E6EBF1;
$light-slate-gray: #6889A9;
$light-steel-blue: #B4C4D4;
$deep-pink: #FF007F;
$light-gray: #CDD7E2;
$dark-gray: #9BB0C5;

$facebook: #3B5998;
$twitter: #55ACEE;
$instagram: #4793BA;

$credibility-bar-bg: #043054;
$filters-bg: #1D4E7D;