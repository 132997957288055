.generic-content{
	margin-top: 50px;
	margin-bottom: 50px;
	.title{
		color: $midnightblue;
		font-weight: 400;
		text-align: center;
		font-size: 50px;
		margin-bottom: 40px;
	}

	.content{
		font-size: 18px;
		color: #36618C;
		max-width: 900px;
		width: 100%;
		margin: 0 auto 40px;
		font-weight: 100;

		strong{
			font-weight: 700;
		}

		a{
			color: #36618C;
		
			&:target:before{
				content: '';
				display: block;
				height: 100px;
				margin-top: -100px;
			}
	
		}
	}
}