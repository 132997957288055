// Base input styles.
// ---------------------------------------------------------

@mixin input {
    appearance: none;
    background: $white;
    border: 2px solid $midnight-gray;
    border-radius: 0;
    box-sizing: border-box;
    color: $midnight-gray;
    display: inline-block;
    font-family: $font-family;
    line-height: $line-height;
    outline: 2px solid transparent;
    vertical-align: top;

    transition-property: background-color, border-color, color, opacity, $focus-property;
    transition-duration: $hover-duration;
    transition-timing-function: $hover-timing-function;

    &:focus {
        border-color: $focus-color;
        box-shadow: $focus-box-shadow;
        outline: 0 none !important;
        outline-offset: 0;
    }

    &:invalid {
        outline: 0 none;
    }
}

// Size variations.
// ---------------------------------------------------------

@mixin input--size($height, $font-size: null) {

    @if $font-size == null {
        $font-size: $height * (15px / $widget-size);
    }

    $padding-base: ($height - (2px * 2) - ($font-size * $line-height)) / 2;
    $padding-ns: $padding-base;
    $padding-ew: 16px;

    font-size: $font-size;
    height: $height;
    padding: $padding-ns $padding-ew;
}

@mixin input--size-default {
    @include input--size($height: $widget-size, $font-size: $font-size);
}

// Hide the spinner buttons from type="number" inputs.
// ---------------------------------------------------------

@mixin input--hide-spinner {

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

// <select>
// ---------------------------------------------------------

@mixin input--type-select {
    padding-left: 12px;

    &::-ms-expand {
        background-color: transparent;
        border-color: transparent;
    }
}

// <textarea>
// ---------------------------------------------------------

@mixin input--type-textarea {
    @include input;

    height: 10em;
    width: 100%;
}
