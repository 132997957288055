/* ----------------------------------------------------
	animation underlines text from the left hand side
   ---------------------------------------------------- */
.ani_underline-from-left
{
	position: relative;
}
.ani_underline-from-left:hover:after
{
	width:100%;
	left: 0;
}
.ani_underline-from-left:after
{
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: "";
	display: block;
	height: 3px;
	left: 0;
	position: absolute;
	background: #2965B0;
	transition: width 1s ease 0s, left 0.5s ease 0s;
	width: 0;
}

/* ----------------------------------------------------
	animation underlines text from the right hand side
   ---------------------------------------------------- */
.ani_underline-from-right
{
	position: relative;
}
.ani_underline-from-right:hover:after
{
	width:100%;
	right: 0;
}
.ani_underline-from-right:after
{
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: "";
	display: block;
	height: 3px;
	right: 0;
	position: absolute;
	background: #2965B0;
	transition: width 1s ease 0s, right 0.5s ease 0s;
	width: 0;
}

/* ------------------------------------------------------------
	animation slides a new background colour in from the left
   ------------------------------------------------------------ */
.ani_fill-from-left
{
    background-repeat: no-repeat;
	background-size: 0% 100%;
	background-position: 0 100%;
	transition: all 0.2s linear;
	background-image: linear-gradient(120deg, #2965B0 0%, #2965B0 100%);
}

.ani_fill-from-left:hover
{
	background-size: 100% 100%;
}

/* ------------------------------------------------------------
	animation slides a new background colour in from the right
   ------------------------------------------------------------ */
.ani_fill-from-right
{
    background-repeat: no-repeat;
	background-size: 0% 100%;
	background-position: 100% 100%;
	transition: all 0.3s ease-in;
	background-image: linear-gradient(120deg, #2965B0 100%, #2965B0 0%);
}

.ani_fill-from-right:hover
{
	background-size: 100% 100%;
}

/* ------------------------------------------------------------
	animation slides a new background colour from bottom to top
   ------------------------------------------------------------ */
.ani_fill-from-bottom{
	background-repeat: no-repeat;
	background-size: 100% 0.0em;
	background-position: 0 100%;
	transition: all 0.2s ease-in;
	background-image: linear-gradient(120deg, #2965B0 0%, #2965B0 100%);	
}

.ani_fill-from-bottom:hover{
	background-size: 100% 100%;
}

/* ------------------------------------------------------------
	animation slides a new background colour from top to bottom
   ------------------------------------------------------------ */
.ani_fill-from-top{
	background-repeat: no-repeat;
	background-size: 100% 0.0em;
	background-position: 0 0;
	transition: all 0.125s ease-in;
	background-image: linear-gradient(120deg, #2965B0 0%, #2965B0 100%);	
}

.ani_fill-from-top:hover{
	background-size: 100% 100%;
}

/* ------------------------------------------------------------
	Image zoom on hover
	Apply image-zoom-trigger to element you want to hover over
	Apply image-zoom-elem to element you want to have the effect
   ------------------------------------------------------------ */
.image-wrapper{
	margin: 0 auto;
}

.image-wrapper,
.image-zoom-elem{
	width: 280px;
	height: 390px;
}

.image-zoom-trigger{
	overflow: hidden;
	&:hover{
		.image-zoom-elem{
			transition: transform .8s cubic-bezier(.35,0,0,1);
			transform: scale(1.05);
		}
	}
	.image-zoom-elem{
		transition: transform .6s cubic-bezier(.35,0,0,1);
	}
}
