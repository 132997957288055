select,
textarea {
    @include input;
    @include input--size-default;
}

input {
    &[type='text'],
    &[type='number'],
    &[type='email'],
    &[type='password'] {
        @include input;
        @include input--size-default;
    }
}

select {
    @include input--type-select;
}

textarea {
    // Allow only vertical resizing of textareas.
    resize: vertical;

    @include input--type-textarea;
}
