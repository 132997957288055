.trapezoid-clip{
	//clip on bottom of element - right side low, left high
	&-bottom{
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);

		&-steep{
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
		}

		//left low - right high
		&-left{
			clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
		}
	}

	//clip on top of element - right side low, left high
	&-top{
		clip-path: polygon(0 0, 100% 11%, 100% 100%, 0 100%);
	}

	// due to the overlapping enquiry form, I've had to do this differently
	&-contact{
		position: relative;

		&:after{
			content:'';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background-color: #E6EBF1;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
		}
	}
}

.triangle-clip{
	&-bottom{
		&-right{
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0%);
		}
	}
}