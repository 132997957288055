.occasion.hero-banner{
	height: 520px;

	@media( min-width: 400px) and ( max-width: map-get($mq-breakpoints, 'tablet-l')){
		height: 300px;
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: 400px){
		height: 200px;
	}
}

.occasion-intro{
	text-align: center;
	background-color: $lavender;
	padding-top: 100px;
	padding-bottom: 100px;

	&.trapezoid-clip-bottom-steep{
		padding-bottom: 300px;
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'tablet-l')){
		padding-top: 40px;
	}

	.title{
		font-size: 50px;
		color: $midnightblue;
		margin-bottom: 40px;

		span{
			color: $deep-pink;
		}
	}
	
	.content{
		font-size: 18px;
		color: #36618C;
		font-weight: 100;
		max-width: 920px;
		width: 100%;
		margin: 0 auto;
	}

	@media( min-width: 400px) and ( max-width: map-get($mq-breakpoints, 'tablet-l')){
		.title{
			font-size: 42px;
		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: 400px){
		.title{
			font-size: 32px;
		}
	}
}

.recommended-products{
	text-align: center;
	margin-top: -190px;
	position: relative;

	.title{
		color: $midnightblue;
		font-size: 30px;
		font-weight: 400;
		margin-bottom: 45px;
	}

	.see-more-wrap{
		display: flex;
		justify-content: center;
		margin-top: 50px;
		margin-bottom: 75px;
	}
}

.occasion-slider{
	position: relative;

	.slick-list{
		padding:0 20% 0 0;
	}

	.slidernav{
		padding: 30px;
		position: absolute;
		top: calc( 50% - 50px );

		.icon{
			width: 40px;
			height: 40px;
			color: $white;
			transition: opacity .2s linear;
			opacity: .4;
		}

		&:hover{
			.icon{
				opacity: 1;
				cursor: pointer;
			}
		}

		&.next{
			right: 0;
		}

		&.prev{
			left: 0;
		}
	}

	.slider-item{
		.image{
			height: 300px;
			width: 475px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			
			@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'tablet-l')){
				width: 320px;			
			}
		}

	}
}