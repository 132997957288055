.easyadmin{
	font-size: .865rem;

	.sidebar-menu a {
	    color: var(--text-color-dark);
	    display: block;
	    line-height: 20px;
	    padding: 4px 5px 10px 0;
	}

	input{
		font-size: 14px;
	}

	#page_fragments,
	#slider_images,
	#product_galleryItems,
	#occasion_galleryItems{

		.field-page_fragment,
		.field-slider_image,
		.field-gallery_item
		{
			border: 2px solid #e3e7ee;
			margin-bottom: 35px;
			padding: 35px;

			.form-widget{
				margin-left: 0;
			}

			.form-control-label{
				text-align: left;
				margin-bottom: 10px;
			}
		}

		.field-vich_image{
			.col-form-label{
				text-align: left;
				margin-bottom: 10px;
			}
		}
	}
}

.fos{
	.form{
		box-shadow: none;
	}
}