@mixin svgicon {
    $size: $line-height * 1em;
    width: $size;
    height: $size;
    display: inline-block;
    fill: currentColor;
    overflow: hidden;
    transform: translateX(0); // This seems to help sub-pixel positioning in Firefox
    vertical-align: top;

    & use {
        pointer-events: none; // http://bugs.jquery.com/ticket/11352
    }
}
