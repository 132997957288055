.blog-latest{
	margin-top: 60px;
	.layout-wrapper{
		.title{
			font-size: 40px;
			font-weight: 400;
			color: $midnightblue;

			span{
				color: $deep-pink;
			}
		}

		.blog-posts{
			margin-top: 60px;
			margin-bottom: 115px;
			display: grid;
			grid-template-columns: repeat( auto-fill, 380px);
			grid-column-gap: 20px;
			grid-row-gap: 80px;
			justify-content: center;
			
			@media(min-width: map-get( $mq-breakpoints, 'phone' )) and ( max-width: map-get( $mq-breakpoints, 'phone-l' ) ){
				grid-template-columns: repeat( auto-fill, 100% );
			}
			
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}
	}
}

.rich-content-intro-home{
	background-color: $lavender;

	.layout-wrapper{
		padding: 80px 20px 455px;
	}

	.content-wrap{
		max-width: 740px;
		width: 100%;
	}

	.title{
		color: $midnightblue;
		font-size: 40px;
		font-weight: 400;
		margin: 0 0 40px;
	}

	.content{
		font-size: 18px;
		font-weight: 100;
		color: $light-slate-gray;
	}

	@media( min-width: map-get( $mq-breakpoints, 'phone'  ) ) and (max-width: map-get($mq-breakpoints, 'phone-l')){
		.layout-wrapper{
			padding-top: 40px;
			padding-bottom: 300px;
		}
	}
}

.popular-units{
	position: relative;
	margin-top: -340px;
	margin-bottom: 90px;
	
	.title{
		font-weight: 400;
		font-size: 40px;
		color: $midnightblue;
		margin-bottom: 27px;
	}
	
	.see-more-wrap{
		text-align: center;
		margin-top: 55px;
		display: flex;
		justify-content: center;
	}

	@media( min-width: map-get( $mq-breakpoints, 'phone'  ) ) and (max-width: map-get($mq-breakpoints, 'phone-l')){
		margin-top: -240px;
	}
}

.occasions{
	margin-top: -70px;
	margin-bottom: 180px;

	.layout-wrapper{
		display: flex;
		flex-direction: row;

		.image-wrap{
			position: relative;
			display: inline-block;
			margin-right: 120px;

			.image{
				width: 480px;
				height: 640px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

			.clipped-block{
				height: 100%;
				max-height: 640px;
				width: 100%;
				background-color: $light-gray; 
				position: absolute;
				top: -20px;
				right: -20px;
				z-index: -1;
			}
		}

		.content{
			.title{
				font-size: 40px;
				color: $midnightblue;
				font-weight: 400;
				margin: 80px 0 0;
			}

			.text{
				margin-top: 50px;
				margin-bottom: 30px;
				color: $light-slate-gray;
				font-size: 18px;			
				font-weight: 100;
			}

			.occasion-list{
				display: grid;
				grid-template-columns: repeat( auto-fill, 220px);
				grid-template-rows: 5fr;
				grid-column-gap: 10px;
				grid-row-gap: 10px;

				.occasion{
					display: flex;
					flex-direction: row;
					align-items: center;
					font-weight: 400;
					color: $midnightblue;
					font-size: 20px;
					text-decoration: none;

					&:hover{
						color: $deep-pink;
					}

					.icon{
						font-size: 14px;
						margin-right: 15px;
					}
				}
			}
		}		
	}

	@media( max-width: map-get($mq-breakpoints, 'desktop') ){
		margin-top: 40px;

		.layout-wrapper{
			flex-wrap: wrap;
			
			.image-wrap{
				margin: 0 auto;
			}

			.content{
				.title{
					margin-top: 40px;
				}
			}

		}	
	}

	@media( max-width: map-get($mq-breakpoints, 'tablet') ){
		.layout-wrapper{
			.image-wrap .image{
				max-width: 100%;
				width: 100%;
				height: auto;
			}
		}
	}

	@media( min-width: map-get( $mq-breakpoints, 'phone'  ) ) and (max-width: map-get($mq-breakpoints, 'phone-l')){
		.layout-wrapper{
			.image-wrap{
				.clipped-block{
					top: -10px;
					right: -10px;
				}
			}
		}
	}
}

.hero-banner{
	position: relative;

	.slider{
		height: 520px;
		
		.item{
			background-size: cover;
			background-repeat: no-repeat;
			height: 520px;
			background-color: rgba(#01161e, 0.7);
			background-blend-mode: multiply;
			background-position: center;	
			
			@supports (-ms-ime-align: auto){
				&::after{
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(#01161e, 0.4);
				}
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				&::after{
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(#01161e, 0.4);
				}
			}

		}

	}

	.overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;

		.title-wrap{
			text-align: center;

			.title{
				font-weight: 400;
				font-size: 40px;
				color: $white;
				margin: 0 0 15px;
			}

			.subtitle{
				font-weight: 100;
				font-size: 30px;
				color: $dark-gray;
			}
		}

		.button-wrap{
			display: flex;
			justify-content: center;
			margin-top: 50px;
			
			.see-full-range-button,
			.get-a-quote-button{
				margin: 0 10px;
			}
		}

		.nav-arrow{
			position: absolute;
			top: calc( 50% - 35px);

			&:hover{
				cursor: pointer;
			
				.icon{
					opacity: 1;
				}
			}

			.icon{
				color: $white;
				width: 30px;
				height: 30px;
				opacity: .6;
				transition: opacity .2s linear;
			}

			&.next{
				right: 10px;
				padding: 20px 0px 20px 20px;

			}

			&.prev{
				left: 10px;	
				padding: 20px 20px 20px 0px;

			}
		}
	}

	@media( max-width: map-get( $mq-breakpoints, 'tablet'  ) ){
		.slider, 
		.slider .item{
			height: 650px;
		}
		
		.overlay{
			.title-wrap{
				padding: 0 20px;
			}
		}	
	}

	@media( min-width: map-get( $mq-breakpoints, 'phone'  ) ) and (max-width: map-get($mq-breakpoints, 'phone-l')){
		.slider{
			max-height: 450px;
		}

		.overlay{
			.overlay-slider{
				max-height: 260px;
			}

			.title-wrap{
				.title{
					font-size: 32px;
				}
				.subtitle{
					font-size: 22px;
				}

				padding: 0;
			}

			.nav-arrow{
				display: none;
			}

			.button-wrap{
				margin-top: 25px;
				flex-wrap: wrap;

				a{
					width: 100%;
					justify-content: center;

					&:last-child{
						margin-top: 10px;
					}
				}
			}

		}
	}

}

.see-full-range-button{
	text-decoration: none;
	background-color: $midnightblue;
	font-size: 18px;
	display: flex;
	align-items: center;
	color: $white;
	padding: 15px;	

	.icon{
		margin-right: 15px;
		width: 28px;
		height: 28px;
	}
}

.get-a-quote-button{
	text-decoration: none;
	background-color: $deep-pink;
	font-size: 18px;
	display: flex;
	align-items: center;
	color: $white;
	padding: 15px;	

	.icon{
		margin-right: 15px;
		width: 28px;
		height: 28px;
	}
}
