// Viewport declaration.
// ---------------------------------------------------------

@-ms-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

// Global overflow hidden
// ---------------------------------------------------------
html {
}

// Global typography.
// ---------------------------------------------------------
body {
    color: $text-color;
    font-family: $urbane;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: normal;
    min-width: 320px;
    overflow-x: hidden;
}

// Global `border-box` sizing.
// ---------------------------------------------------------
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }

    &:focus {
        outline: 0 none;
    }
}

// Text-selection style.
// ---------------------------------------------------------

::selection {
    background: rgba($selection-color, .9);
    color: contrasting-color($selection-color);
    text-shadow: none;
}

// Reset anchor styles.
// ---------------------------------------------------------

a {
    color: $link-color;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: $hover-duration;
    transition-timing-function: $hover-timing-function;

    &:active,
    &:hover {
        color: $link-color--active;
    }
}

// Reset <address> styles.
// ---------------------------------------------------------

address {
    display: block;
    font-style: inherit;
}

// Remove baseline gap from inline-block elements.
// ---------------------------------------------------------

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: top;
}

// Touch interaction.
// ---------------------------------------------------------

a,
label,
button {
    touch-action: manipulation;
}

//content width 20px padding l/r
$content-width: 1220px;

.layout-wrapper{
    max-width: $content-width;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}


p,
h1,
h2,
h3,
h4,
h5,
h6{
    margin: 0;
}

.disable-select{
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.hero-banner{
    .image{
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}