#occasions-page-intro{
	text-align: center;
	padding-top: 90px;
	padding-bottom: 300px;
	background-color: $white;

	.title{
		color: $midnightblue;
		font-size: 50px;
		font-weight: 400;
		margin-bottom: 40px;
	}

	.content{
		font-size: 20px;
		color: #36618C;

		max-width: 745px;
		width: 100%;
		margin: 0 auto;
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet-l') ){
		padding-top: 40px;
	}

	@supports (-ms-ime-align: auto){
		padding-bottom: 90px;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
		padding-bottom: 90px;
	}
}

#occasions-list{
	background-color: $lavender;
	margin-top: -235px;
	padding-bottom: 120px;

	@supports (-ms-ime-align: auto) {
		padding-top: 90px;
		margin-top: 0;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
		padding-top: 90px;
		margin-top: 0;
	}


	.occasions-grid-wrapper{
		display: grid;
		grid-template-columns: repeat(auto-fill, 380px);
		grid-column-gap: 20px;
		grid-row-gap: 80px;
		justify-content: center;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.occasion{
				max-width: 380px;
				width: 100%;
				margin-bottom: 80px;
			}
		}

		.occasion{
			position: relative;

			.occasion-image{
				display: block;
				background-color: rgba(0, 0, 0, .3);
				background-blend-mode: multiply;
				width: 100%;
				height: 410px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				transition: background-color .2s linear;
				z-index: 1;
				position: relative;

				@supports (-ms-ime-align: auto) {
					&::after{
						content: '';
						display: block;
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.4);
						transition: background-color .2s linear;
					}
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					&::after{
						content: '';
						display: block;
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.4);
						transition: background-color .2s linear;
					}
				}

			}

			.clipped-block{
				position: absolute;
				top: 10px;
				right: 10px;
				background-color: #ACBBC6;
				max-width: 380px;
				width: 100%;
				max-height: 410px;
				height: 100%;
				z-index: 0;
			}

			.occasion-meta{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 1;
				padding: 40px 40px;

				.title{
					font-size: 35px;
					color: $white;
					font-weight: 400;
					margin-bottom: 30px;
				}

				.content{
					font-size: 18px;
					color: $white;
					opacity: 0;
					transition: opacity .2s linear;
					font-weight: 100;
				}

				@media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'phone-l')){
					padding: 40px 15px;

					.title{
						margin-bottom: 15px;
						line-height: 105%;
					}
				}
			}

			a:hover{
				.occasion-image{
					background-color: rgba($credibility-bar-bg, .7);
				}

				.content{
					opacity: 1;
				}

				@supports (-ms-ime-align: auto) {
					.occasion-image{
						&::after{
							background-color: rgba($credibility-bar-bg, 0.9);
						}
					}
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					.occasion-image{
						&::after{
							background-color: rgba($credibility-bar-bg, 0.9);
						}
					}
				}
			}

			@media(min-width: map-get($mq-breakpoints, 'phone')) and (max-width: map-get($mq-breakpoints, 'tablet')){
				.occasion-image{
					background-color: rgba($credibility-bar-bg, .7);
				}

				.occasion-meta{
					.content{
						opacity: 1;
					}
				}

				@supports (-ms-ime-align: auto) {
					.occasion-image{
						&::after{
							background-color: rgba($credibility-bar-bg, 0.9);
						}
					}
				}

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					.occasion-image{
						&::after{
							background-color: rgba($credibility-bar-bg, 0.9);
						}
					}
				}
			}
		}

		@media( min-width: map-get($mq-breakpoints, 'phone') ) and ( max-width: map-get($mq-breakpoints, 'phone-l') ){
			grid-template-columns: repeat(auto-fill, 100%);
			grid-row-gap: 40px;

			.occasion{
				.occasion-image{
					height: 350px;
				}
			}
		}

	}
}

#long-term-hire{
	background-color: $lavender;
	padding-top: 100px;
	padding-bottom: 110px;

	@media( min-width: map-get($mq-breakpoints, 'phone') ) and ( max-width: map-get($mq-breakpoints, 'phone-l') ){
		padding-top: 60px;
	}


	.title{
		font-size: 35px;
		color: $midnightblue;
		font-weight: 400;
		margin-bottom: 30px;
	}

	.content{
		color: #36618C;
		font-size: 18px;
		font-weight: 100;

		p:first-child{
			font-size: 20px;
			font-weight: 400;
		}
	}

	.get-in-touch{
		display: inline-block;
		margin-top: 40px;
	}
}