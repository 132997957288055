.contact{
	padding-top: 95px;
	padding-bottom: 45px;
	
	.layout-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
		.col{
			width: 100%;
			&.left{
				max-width: 35%;
				margin-right: 50px;
			}
			&.right{
				max-width: 680px;
				position: relative;
			}
		}
	}
	
	.title{
		color: $midnightblue;
		font-size: 50px;
		font-weight: 400;
		margin-top: 45px;

		span{
			color: $deep-pink;
		}
	}

	.content{
		margin-top: 40px;
		font-size: 20px;
		color: #36618C;
		font-weight: 100;

		p:first-child{
			font-size: 24px;
		}

		strong{
			font-weight: 900;
		}

		a{
			text-decoration: none; 
			color: #36618C;

			&:hover{
				color: $deep-pink;
			}


		}
	}

	@media( max-width: map-get($mq-breakpoints, 'desktop')){
		padding-top: 20px;

		.layout-wrapper{
			flex-direction: column;

			.col{
				&.left, 
				&.right{
					max-width: 100%;
				}

				&.left{
					margin-bottom: 40px;
				}
			}
		}
	}
}

.form{
	padding: 45px 40px;
	background-color: $white;
	box-shadow: 5px 5px 20px -1px rgba(0,0,0,0.2);
	
	.basic-info,
	.event-info
	{
		margin-bottom: 40px;
	}

	h3{
		font-size: 22px;
		color: $midnightblue;
		margin-bottom: 15px;
	}

	.form-row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 20px;

		.form-col{

			&.half{
				max-width: 290px;
				width: 100%;
			}

			&.full{
				max-width: 100%;
				width: 100%;
			}

			input,
			textarea,
			select{
				width: 100%;
				background-color: #F3F5F8;
				font-family: $urbane;
				font-size: 18px;
				height: 50px;
				padding: 10px 15px;
				color: #9BB0C5;
				border: 2px solid transparent;
				transition: border-color .2s linear;
				
				&::placeholder,
				&[type="date"]
				{
					color: #9BB0C5;
					opacity: 1;
				}

				&:-webkit-autofill{
					-webkit-text-fill-color: #9BB0C5;
					-internal-autofill-selected: #9BB0C5;
				}

				&:focus{
					box-shadow: none;
					border-bottom-color: $deep-pink;
				}

			}

			textarea{
				min-height: 190px;
				padding: 20px;
			}

			select{
				color: #9BB0C5;
				background-image: url('/images/icons/form-dropdown-arrow.png');
				background-repeat: no-repeat;
				background-position: 90% center;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
					background-image: none;
				}

			}

			p{
				font-size: 14px;
				color: #36618C;
				font-weight: 100;
			
				a{
					text-decoration: none;
					color: $deep-pink;
				}
			}
		}


		&.submit{
			margin-bottom: 0;

			button{
				font-size: 18px;
				font-weight: 400;
				background-color: $deep-pink;
				color: $white;
				border: none;
				padding: 20px;
				transition: background-color .2s linear;
			
				&:hover{
					cursor: pointer;
					background-color: #e00086;
				}
			}
		}

		@media( max-width: map-get($mq-breakpoints, 'desktop-l')){
			.form-col{
				&.half{
					max-width: 100%;
					&:first-child{
						margin-bottom: 20px;
					}
				}
			}
		}

		.g-recaptcha{
			@media( min-width: map-get($mq-breakpoints, 'phone')) and (max-width: 400px){
				transform:scale(0.77);
				transform-origin:0 0;
			}
		}
	}

	@media( min-width: map-get($mq-breakpoints, 'phone')) and ( max-width: map-get($mq-breakpoints, 'phone-l')){
		padding: 40px 30px;
	}

	.alert{
		color: $midnightblue;
		padding: 10px;
		margin-top: 15px;
		border: 2px solid $deep-pink;
		border-radius: 5px;
		font-size: 16px;

		&.success{
		}
	}
}

.hero-banner.contact-us{
	height: 525px;
	margin-top: -210px;
	z-index: -2;
}

.call-to-action-bar#contact{
	.title{
		text-align: center;
		max-width: 100%;
	}
}