.call-to-action-bar{
	background-color: $midnightblue;
	padding: 70px 0;
	position: relative;
	overflow-y: hidden;

	.layout-wrapper{
		max-width: 980px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		@media(max-width: map-get($mq-breakpoints, 'desktop')){
			flex-wrap: wrap;
			justify-content: center;

			.title{
				margin-bottom: 20px;
				text-align: center;
			}

			.icon--logos__logo-sign{
				right: 5%;
			}
		}
	}

	.title{
		margin: 0;
		color: $white;
		font-size: 36px;
		font-weight: 400;
		max-width: 665px;
		z-index: 1;
	
		span{
			color: $deep-pink;
		}

		a{
			text-decoration: none;
		}
	}


	.icon--logos__logo-sign{
		position: absolute;
		width: 285px;
		height: 325px;
		top: -15px;
		color: #1D4E7D;
		right: 20%;
	}

	&.alt-colour{
		background-color: $white;

		.title{
			color: $midnightblue;
		}

		.icon--logos__logo-sign{
			color: #E3E9EF;
		}
	}
}