.post{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		max-width: 380px;
		width: 100%;

		a{
			width: 100%;
		}
	}

	a{
		text-decoration: none;
		opacity: 1;
		transition: opacity .2s linear;

		&:hover{
			opacity: .7;
		}
	}
	
	span{
		display: block;
	}

	.image{
		height: 330px;
		width: 380px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@media(min-width: map-get( $mq-breakpoints, 'phone' )) and ( max-width: map-get( $mq-breakpoints, 'phone-l' ) ){
			width: 100%;
		}
	}

	.clipped-block{
		max-width: 380px;
		width: 100%;
		max-height: 330px;
		height: 100%;
		top: 10px;
		right: 10px;
		position: absolute;
		background-color: $light-gray;
		z-index: -1;					
	}

	.post-meta{
		margin-top: 40px;

		.post-title,
		.post-date,
		.post-excerpt{
			margin: 0;
		}
		
		.post-title{
			font-size: 26px;
			font-weight: 400;
			color: $midnightblue;
		}

		.post-date{
			font-size: 18px;
			padding: 15px 0 20px;
			border-bottom: 1px solid $lavender;
			margin-bottom: 20px;
			color: $midnightblue;
			font-weight: 100;
		}

		.post-excerpt{
			font-size: 18px;
			color: $light-slate-gray;
			font-weight: 100;
		}
	}
}